import * as requests from "@/API/requests";

const financialFreedomCalculator = {
  namespaced: true,
  state: () => ({
    input: {
      user_monthly_savings: 0,
      date_of_birth: "",
      user_income: 0,
      years: 0,
    },
    ff_number: 0,
    ff_savings: 0,
    ff_age: "",
    result: { result: {} },
    onboarding_result: {},
  }),
  mutations: {
      setMonthlySavings(state, payload) {
          state.input.user_monthly_savings = payload;
      },
      setDateOfBirth(state, payload) {
          state.input.date_of_birth = payload;
      },
      setUserIncome(state, payload) {
          state.input.user_income = payload;
      },
      setYears(state, payload) {
          state.input.years = payload;
      },
      setResult(state, payload) {
          state.result = payload;
      },
      setFfNumber(state, payload) {
          state.ff_number = payload;
      },
      setFfSavings(state, payload) {
          state.ff_savings = payload;
      },
      setFfAge(state, payload) {
          state.ff_age = payload;
      },
      setOnboardingResult(state, payload) {
          state.onboarding_result = payload;
      },
  },
  actions: {
      async calculateFinancialFreedom(store, payload) {
          const result = await requests.calculateFinancialFreedomRequest(payload);
          // store.commit("setYears",result.age)
          store.commit("setResult", result);
          return result;
      },
      async updateFinancialFreedom(store, payload) {
          return await requests.updateFinancialFreedomAllocationRequest(payload);
      },
      async calculateInitialFinancialFreedom(store, payload) {
          return await requests.onboardingFinancialFreedomCalculatorRequest(
              payload
          );
      },
      async getFinancialFreedomMilestones() {
          return await requests.getFinancialFreedomMilestones();
      },
      async getFinancialFreedomResult(store) {
          const result = await requests.financialFreedomResultRequest();

          store.commit("setOnboardingResult", {
              ff_no: result.result_1.ffnumber.ffnumber,
              difference: result.result_1.years.difference,
              age_no_ap: result.result_1.age.age_no_ap,
              age_ap: result.result_1.age.age_ap,
              income: result.response.income,
              savings: result.response.savings,
              rate: result.response.savingsrate * 100,
              ff_no_desc: result.result_1.ffnumber.ffnumber_desc,
              age_today: result.result_1.age.age_today,
          });
          return result;
      },
      async getFinancialFreedomConfig(store) {
          const result = await requests.getFinancialParamsRequest();
          store.commit("setMonthlySavings", result.user_monthly_savings);
          store.commit("setDateOfBirth", result.date_of_birth);
          store.commit("setUserIncome", result.user_income);
          store.commit("setFfNumber", result.ff_number);
          store.commit("setFfSavings", result.ff_savings);
          store.commit("setFfAge", result.ff_age);
          return result;
      },
    async updateStage(store, payload) {
      return await requests.updateUserStageRequest(payload);
    },
    async createFinancialFreedomGoal(store, payload) {
        return await requests.createFinancialFreedomGoal(payload);
      },
  },
  getters: {},
};

export default financialFreedomCalculator;
