export const reInitializedWebflowInteractions = () => {
  const Webflow = window.Webflow;
  //RE-INIT WF as Vue.js init breaks WF interactions
  if (Webflow) {
    Webflow.destroy();
    Webflow.ready();
    Webflow.require("ix2").init();
  }
  document.dispatchEvent(new Event("readystatechange"));
  initializeDatePicker();
  initializeEmbeddedEducation();
  initializeNumberComma();
  setNavbarValues();
  initializeToolTip();
  // $('.loading-screen').remove();
};
export const setNavbarValues = () => {
  const $store = window.$store;
  if ($store.getters["auth/data"].fname) {
    const elements = document.querySelectorAll(
      ".navbar_title_wrapper>.text-size-small",
    );
    elements.forEach((el) => {
      el.innerHTML = $store.getters["auth/data"].fname;
    });
  }
  const profile_element = document.getElementById("navbar_profile");
  if (profile_element) {
    profile_element.style.backgroundImage = `url('${$store.getters["auth/data"].p_image}')`;
    profile_element.style.cursor = "pointer";
    profile_element.addEventListener(
      "click",
      () => (window.location.href = "/app/account-settings"),
    );
  }
};
export const initializeEmbeddedEducation = () => {
  const elements = document.querySelectorAll(".has_embeddededucation");

  elements.forEach((element) => {
    const input = element.querySelector("input");
    const educationElement = element.querySelector(".embedded_education");

    educationElement.style.display = "none";

    input.addEventListener("focus", function () {
      educationElement.style.display = "block";
    });
  });
};
export const initializeNumberComma = () => {
  function addNumberComma(event) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) {
      event.preventDefault();
    }
    const num = event.target.value.replace(/,/gi, "");
    // the following line has been simplified. Revision history contains original.
    event.target.value = num.split(/(?=(?:\d{3})+$)/).join(",");
  }

  document.querySelectorAll("input[data-type='number']").forEach((element) => {
    element.addEventListener("keyup", addNumberComma);
  });
};
// will look into this incase it breaks other instances
export const initializeDatePicker = (
  selector = '[data-toggle="datepicker"]',
  format = "dd/mm/yyyy",
  minViewMode = "days",
) => {
  // var $ = window.$;
  if (typeof $(selector)?.datepicker === "undefined") {
    return;
  }
  // made changes concerning the date picker.

  $(selector)
    ?.datepicker("destroy")
    .datepicker({
      format: format,
      dateFormat: format,
      startView: 2,
      minViewMode,
      starView: minViewMode,
      date:
        document.title === "On-Boarding" 
          ? new Date().setFullYear(new Date().getFullYear() - 30)
          : new Date(),
      autoHide: true,
    });
  // Available date placeholders:
  // Year: yyyy
  // Month: mm
  // Day: dd
  if (window.innerWidth < 768) {
    $('[data-toggle="datepicker"]')?.attr("readonly", "readonly");
  }
};
export const initializeToolTip = () => {
  // Get the tooltip element
  const tooltip1 = document.getElementById("tooltip-1");
  const tooltip2 = document.getElementById("tooltip-2");
  // Get the tooltip content element
  const tooltipContent = document.getElementById("tooltipcontent1");
  const tooltipContent2 = document.getElementById("tooltipcontent2");

  // Add click event listener to the tooltip
  tooltip1?.addEventListener("click", () => {
    // Toggle the "show" class to show or hide the tooltip content
    tooltipContent?.classList.toggle("show");
  });

  // Add click event listener to the tooltip
  tooltip2?.addEventListener("click", () => {
    // Toggle the "show" class to show or hide the tooltip content
    tooltipContent2?.classList.toggle("show");
  });
};
export const formatNumber = (number, decimals = 2) => {
  // format the number using toLocaleString()
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
};

export const addValidatorMethods = (_this) => {
  $.validator.addMethod(
    "pattern",
    function (value, element, param) {
      if (this.optional(element)) {
        return true;
      }
      if (typeof param === "string") {
        param = new RegExp("^(?:" + param + ")$");
      }
      return param.test(value);
    },
    "Invalid format.",
  );

  $.validator.addMethod(
    "enforceMinAmount",
    function (value, element) {
      return this.optional(element) || parseInt(value.replaceAll(",", ""));
    },
    "Please, the minimum amount should at least be 1",
  );

  $.validator.addMethod("dateAP", function (value) {
    //var regExp = /[_\-!\"@;,.:]/;
    var regExp =
      /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    return regExp.test(value);
  });

  $.validator.addMethod(
    "pattern",
    function (value, element, param) {
      if (this.optional(element)) {
        return true;
      }
      if (typeof param === "string") {
        param = new RegExp("^(?:" + param + ")$");
      }
      return param.test(value);
    },
    "Invalid format.",
  );

  // Validator methods for kyc and lifegoal forms
  $.validator.addMethod(
    "validGoalName",
    function (value, element) {
      return this.optional(element) || /^([a-zA-Z ]){1,50}$/.test(value);
    },
    "Please enter a valid goal name",
  );
};
export async function redirectAuthenticatedUseToCorrectRoute(context) {
  const response = await context.$store.dispatch("auth/loadRoute");
  switch (response.login.stage) {
    case "onboarding":
      window.location.href = "/on-boarding/on-boarding";
      break;
    case "email_verification":
      window.location.href =
        "/auth/verify-email?em=" + encodeURIComponent(response.email);
      break;
    case "nonInvestor":
      window.location.href = "/on-boarding/your-plan";
      break;
    case "investor":
    case "live":
    case "first":
      await context.$store.dispatch("auth/loadData").then(() => {
        window.location.href = "/app/dashboard";
      });
      break;
  }
}

export function traditionallyNavigateTo(route) {
  const element = document.createElement("a");
  element.href = route;
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
}
