import * as requests from "../API/requests";

const authStore = {
  namespaced: true,
  state: () => ({
    token: "",
    user: "",
    accountStatus: "",
    resetEmail: "",
    data: "",
    notifications: {},
    verification_email_sent: false,
    notification_last_fetched_at: null,
    displayNewFeatureModal: true,
    googleAuthError: "",
    authInProgress: false,
  }),
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setAccountStatus(state, payload) {
      state.accountStatus = payload;
    },
    setResetEmail(state, payload){
      state.resetEmail = payload;
    },
    setData(state, payload) {
      state.data = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setVerificationEmailSent(state, payload){
      state.verification_email_sent = payload;
    },
    setNotificationLastFetchedAt(state, payload) {
      state.notification_last_fetched_at = payload;
    },
    setShouldDisplayNewFeatureModal(state, payload) {
      state.displayNewFeatureModal = payload;
    },
    setGoogleAuthError(state, payload) {
      state.googleAuthError = payload;
    },
    setAuthInProgress(state, payload) {
      state.authInProgress = payload;
    }
  },
  actions: {
    async loginUser(store, payload) {
      const login = await requests.loginRequest(payload);
      store.commit("setToken", login.authToken);
      return login;
    },
    async checkAccountStatus(store) {
      const status = await requests.checkAccountStatusRequest();

      store.commit("setAccountStatus", status);
      return status;
    },
    async checkAuthStatus() {
      return await requests.checkAuthStatusRequest();
    },
    async loadData(store) {
      const response = await requests.getUserDataRequest();

      store.commit("setData", response);
      return response;
    },
    async loadRoute(store) {
      const response = await requests.getAuthRedirectRouteUrlRequest();
      store.commit("setUser", response);
      return response;
    },
    async signUpUser(store, payload) {
      const response = await requests.signUpRequest(payload);
      store.commit("setToken", response.authToken);
      return response;
    },
    async getGoogleAuthUrl(_, params) {
      return await requests.googleAuthUrlRequest(params);
    },
    async continueGoogleSignUp(_, payload) {
      return await requests.googleSignUpContinueRequest(payload);
    },
    async sendForgotPasswordEmail(store, payload) {
      return await requests.sendForgotPasswordEmailRequest(payload);
    },
    async validatePasswordResetToken(store, payload) {
      return await requests.validatePasswordResetTokenRequest(
          payload
      );
    },
    async resetPassword(store, payload) {
      return await requests.resetPasswordRequest(payload);
    },
    async resendEmailBackup(store, payload) {
      return await requests.resendEmailBackupRequest(payload);
    },
    async getUserDetails() {
      return await requests.checkAuthStatusRequest();
    },
    async getToken() {
      return await requests.getTokenRequest();
    },
    async getChecklist(_, payload) {
      return await requests.loadChecklistRequest(payload);
    },
    async getAccountSettingsUserData() {
      return await requests.accountSettingsUserDataRequest();
    },
    async verifyEmailCode(_, payload) {
      return await requests.verifyEmailCodeRequest(payload);
    },
    async accountSettingsResetPassword(_, payload) {
      return await requests.accountSettingsResetPasswordRequest(payload);
    },
    async accountSettingsUploadProfile(_, payload) {
      return await requests.accountSettingsUploadProfileRequest(payload);
    },
    async getSubscriptionData() {
      return await requests.getSubscriptionDetailsRequest();
    },
    async addPhoneNumber(_, payload) {
      return await requests.addPhoneNumberRequest(payload);
    },
    async getNotifications(store) {
      const response = await requests.getNotificationsRequest();
      store.commit("setNotifications", response);
      store.commit("setNotificationLastFetchedAt", new Date());
      return response;
    },
    async sendContactUs(_, payload) {
      return await requests.sendContactUsRequest(payload);
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
    }
  },
  getters: {
    data(state) {
      return {
        fname: state.data.result_1?.firstname,
        ff_no: state.data.financial_data?.ffnumber?.ffnumber,
        difference: state.data.financial_data?.years?.difference,
        income: state.data.payload?.income,
        savings: state.data.payload?.savings,
        rate: state.data.payload?.savings_rate,
        ff_no_desc: state.data.financial_data?.ffnumber?.ffnumber_desc,
        age_no_ap: state.data.financial_data?.age?.age_no_ap,
        age_ap: state.data.financial_data?.age?.age_ap,
        age_today: state.data.financial_data?.age?.age_today,
        rate_message: state.data.payload?.message,
        kyc: state.data.payload?.kyc,
        stage: state.data.result_1?.login.stage,
        bgColor: state.data.payload?.bgColor,
        color: state.data.payload?.color,
        p_image: state.data.payload?.profile,
      };
    },
    notifications(state) {
      return state.notifications;
    },
    isInvestor(state) {
      return state.data.result_1?.has_paid_joining_fee;
    },
    newAccount(state) {
      return state.user.created_at || null;
    },
  },
};

export default authStore;
