import store from "@/store";
import $store from "@/store";
import {createApp, defineAsyncComponent} from "vue";
import Reauthenticate from "@/views/auth/Reauthenticate.vue";
import httpClient from "@/API/httpClient";
import vue3GoogleLogin from "vue3-google-login";
import {GOOGLE_CLIENT_ID} from "@/constants";

export const waitForAuthComplete = async () => {
    return new Promise((resolve) => {
        const checkAuthInProgress = () => {
            const authInProgress = $store.state.auth.authInProgress;
            if (!authInProgress) {
                resolve();
            } else {
                setTimeout(checkAuthInProgress, 100); // Check again in 100 milliseconds
            }
        };
        checkAuthInProgress();
    });
};

function retryRequest(config) {
    return new Promise((resolve, reject) => {
        httpClient.request(config)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
    });
}

export async function reAuthenticate(error) {
    if (!store.state.auth.user?.email) {
        return Promise.reject(error);
    }

    if ($store.state.auth.authInProgress) {
        await waitForAuthComplete();
        return retryRequest(error.config);
    }
    $store.commit("auth/setAuthInProgress", true)
    const container = document.createElement('div')
    container.id = 'login-modal-container'
    document.body.appendChild(container)
    return new Promise((resolve, reject) => {
        const LoginWithGoogleComponent = defineAsyncComponent(() =>
            import("@/components/LoginWithGoogleComponent.vue"),
        );
        const app = createApp(Reauthenticate, {
                onSuccess: () => {
                    $store.commit("auth/setAuthInProgress", false)
                    retryRequest(error.config)
                        .then((response) => resolve(response))
                        .catch((err) => reject(err))
                }
            }
        );
        app.use(vue3GoogleLogin, {clientId: GOOGLE_CLIENT_ID})
        app.component("login-with-google-component", LoginWithGoogleComponent);
        app.mount('#login-modal-container');
    });
}


export function handleGoogleOauthSuccess(response) {
    let url = "";
    $store.commit("auth/setToken", response.authToken);
    switch (response.action) {
        case "login":
            url = "/auth/route";
            break;
        case "signup":
            url = "/auth/account-verified-phone-number?id=" + response.authToken;
            break;
    }
    if (url) {
        window.location.href = url;
    }

}