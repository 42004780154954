import axios from "axios";
import {cacheAdapterEnhancer} from "axios-extensions";
import {BASE_URL, GOOGLE_SIGNUP_CONTINUE_URL, LOGIN_URL} from "./url";
import adapter from "axios/lib/adapters/xhr";
import {notify} from "@kyvg/vue3-notification";
import $store from "@/store";
import {reAuthenticate, waitForAuthComplete} from "@/misc/auth";

axios.defaults.adapter = adapter;

const cacheConfig = {
  enabledByDefault: false,
  cacheFlag: "useCache",
};

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, cacheConfig),
});

// This interceptor sets the authtoken automatically if it exists in the store
const authInterceptor = async (config) => {
  // if the url is not the login url, wait for auth to complete
  if (!config.url.includes(LOGIN_URL) && !config.url.includes(GOOGLE_SIGNUP_CONTINUE_URL)) {
    await waitForAuthComplete();
  }
  const token = $store.state.auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};


// Interceptor for responses
const responseInterceptor = (response) => {
  return response;
};

// interceptor to catch errors
const errorInterceptor = async (error) => {
  // check if it's a server error
  if (!error.response) {
    notify({
      title: "Network error",
      text: "Please try refreshing your browser",

      type: "warn",
    });
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 401:
      return reAuthenticate(error);
    default:
      console.error(error);
  }
  return Promise.reject(error);
};
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
export default httpClient;
