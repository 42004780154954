import * as requests from "../API/requests";

const makeInvestmentStore = {
  namespaced: true,

  state: () => ({
    plan: null,
    transaction: null,
    receipt: null,
  }),
  mutations: {
    setPlan(state, payload) {
      state.plan = payload;
    },
    setTransaction(state, payload){
      state.transaction = payload;
    },
    setReceipt(state, payload) {
      state.receipt = payload;
    },
  },
  actions: {
    async getUserPlan(store) {
      const data = await requests.getPlanRequest();
      store.commit("setPlan", data);
      return data;
    },
    async getUserTransaction(store, payload) {
      const data = await requests.getUserTransactionRequest(payload);
      store.commit("setTransaction", {...data, transaction_ref: data.reference});
      return data;
    },
    async generateReceipt(store, payload) {
      const data = await requests.generateReceiptRequest(payload);
      store.commit("setReceipt", data);
      return data;
    },
    async submitInvestmentDetails(store, payload) {
      const data = await requests.submitInvestmentDetailsRequest(payload);
      store.commit("setTransaction", data);
      return data;
    },
    async payWithCard(_, payload) {
      const data = await requests.paywithCardRequest({ ab: true, ...payload });
      return data.data;
    },
    async payWithMpesa(_, payload) {
      return await requests.paywithMpesaRequest(payload);
    },
    async makeTransaction(_, payload) {
      return await requests.makeTransactionRequest(payload);
    },
  },
  getters: {},
};
export default makeInvestmentStore;
