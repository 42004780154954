<template>
  <div style="display: flex; justify-content: center; margin: auto">
    <div v-if="incompatibleBrowser" class="is-warning">
      <small>If you encounter any issues with Google Login, please attempt to use
        a browser that is compatible with the feature.</small>
    </div>
    <GoogleLogin :callback="callback" :error="handleOnError" prompt></GoogleLogin>
  </div>
</template>
<script>
import {GoogleLogin} from "vue3-google-login";
import $store from "@/store";

var $ = window.$;
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    onError: Function,
  },
  data() {
    return {
      isLoading: false,
      error: "",
      incompatibleBrowser: false
    };
  },
  components: {
    GoogleLogin
  },
  computed: {},
  methods: {
    handleOnError(error){
      console.log(error)
      console.log(typeof this.onError)
      if (typeof this.onError === 'function'){

        this.onError(error)
      }
    },
    isiOS() {
      return (
          [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
          ].includes(navigator.platform) ||
          (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    async callback(response) {
      $store.dispatch("auth/continueGoogleSignUp", {credential: response.credential})
          .then((data) => {
            $store.commit("auth/setToken", data.authToken);
            this.onSuccess(data)
          })
          .catch(err => {
            if (typeof this.onError === 'function') {
              this.onError(err)
            }
          })
    },
  },
  mounted() {
    if (navigator.userAgent.includes("Instagram") && !this.isiOS()) {
      this.incompatibleBrowser = true;
      $(".or_separator").css("opacity", "0");
    }
  }
};
</script>
